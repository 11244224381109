import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: () => import(/* webpackChunkName: "login" */ '../views/login.vue'),
  // },
  {
    path: '/prorrogas',
    name: 'Prorrogas',
    component: () => import(/* webpackChunkName: "prorrogas" */ '../views/prorrogas.vue'),
  },
  {
    path: '/upload',
    name: 'Uploads',
    component: () => import(/* webpackChunkName: "uploads" */ '../views/upload.vue'),
  },
  // {
  //   path: '/citas-pasaportes',
  //   name: 'Citas Pasaportes',
  //   component: () => import(/* webpackChunkName: "passportAppointments" */ '../views/passportCalendar.vue'),
  // },
  // {
  //   path: '/envio_de_pasaportes',
  //   name: 'Envio de Pasaportes',
  //   component: () => import(/* webpackChunkName: "envios_de_pasaportes" */ '../views/envio_de_pasaportes.vue')
  // },
  {
    path: '/registro-consular',
    name: 'Registro Consular',
    component: () => import(/* webpackChunkName: "registro_consular" */ '../views/registro_consular.vue')
  },
  {
    path: '/perfil-de-usuario',
    name: 'Perfil Usuario',
    component: () => import(/* webpackChunkName: "user_profile" */ '../views/userProfile.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router