<template>
  <b-nav tabs fill>
    <b-nav-item :to="{name: 'Uploads'}">Carga de Datos</b-nav-item>
    <b-nav-item :to="{name: 'Prorrogas'}">Prórrogas</b-nav-item>
    <!-- <b-nav-item :to="{name: 'Citas Pasaportes'}">Citas de Pasaportes</b-nav-item> -->
    <!-- <b-nav-item :to="{name: 'Envio de Pasaportes'}">Envio de Pasaportes</b-nav-item> -->
    <b-nav-item :to="{name: 'Registro Consular'}">Registro Consular</b-nav-item>
    <b-nav-item v-if="$store.state.session" :to="{name: 'Perfil Usuario'}">Perfil Usuario</b-nav-item>
    </b-nav>
</template>

<script>
export default {
  name: 'Menu',
  components: {
    
  }
}
</script>