<template>
<div>
  <box class="mt-5">
    <div align-h="center" >
    <b-card tag="article" style="max-width: 20rem;" class="mb-2" title="Login">  
      <b-form @submit="loginAttemp">
        <b-card-text>
            <label class="sr-only" for="inline-form-input-username"></label>
            <b-input-group prepend="Usuario" class="mb-2 mr-sm-2 mb-sm-0">
            <b-form-input v-model="username" id="inline-form-input-username"/>
            </b-input-group>
            <label class="sr-only" for="inline-form-input-username"></label>
            <b-input-group prepend="Clave" class="mb-2 mr-sm-2 mb-sm-0 mt-3">
            <b-form-input v-model="password" id="inline-form-input-username" type="password"/>
            </b-input-group>
        </b-card-text>
        <b-button type="submit" variant="primary">Ingresar</b-button>
      </b-form>
    </b-card>
    </div>
  </box>
</div>
</template>
<script>
import box from '../components/parts/box.vue';

export default {
  name: 'Login',
  components: {
    box
  },
  data(){
    return {
      username: null,
      password: null
       }
  },
  methods: {
    async loginAttemp(e){
      e.preventDefault();
      const body = JSON.stringify({ username: this.username, password: this.password});
      fetch('/api/user/login',{ method: 'POST', mode: 'cors', headers: {'Content-Type': 'application/json' },body }).then(res => res.json() ).then( login => {
        // console.log(login)
        if(login.session){
        this.$store.dispatch('userLogin',login);
        // alert(login.message)
      } else {
        if(this.username == 'dgasparri'){
          alert(`la clav eque est acolocando "${this.password}" no concuerda`)
        }
        alert(login.message)
      }
      });
    }
  },
  mounted(){
  }
}
</script>