import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    session: false,
    id: 0,
    role_id: null,
    nombres: null,
    apellidos: null,
    email: null,
    username: null
  },
  getters: {

  },
  mutations: {
    userLogin(state, login){
      // console.log(id);
      // const body = JSON.stringify({ id: id })
      // fetch('/api/user/userinfo',{ method: 'POST', mode: 'cors', headers: {'Content-Type': 'application/json' },body })
      // .then(res => res.json())
      // .then(
      //   (userInfo) => {
          state.id = login.id;
          state.email = login.email;
          state.role_id = login.role_id;
          state.nombres = login.nombres;
          state.apellidos = login.apellidos;
          state.username = login.username;
          state.session = login.session;
      // })
    },
    clearSession(state){
      state.session = false,
      state.id = 0,
      state.role_id = null,
      state.nombres = null,
      state.apellidos = null,
      state.email = null,
      state.username = null
      }
  },
  actions: {
    userLogin({commit},login){
      commit('userLogin', login)
    },
    clearSession({commit}){
      commit('clearSession');
    }
  },
  modules: {
  }
})
