<template>
  <b-container fluid>
    <div class="header-gradient py-2">
      <b-row>
        <b-col cols="3">
          <div>
            <b-img src="./../../assets/escudo.png" class="float-right"></b-img>
          </div>
        </b-col>

        <b-col cols="9" class="text-left mt-3">
          <div class="text-white" >
            <h3>{{ title }}</h3>
          </div>
        </b-col>
      </b-row>
      <b-button v-if="$store.state.session" variant="primary" @click="$store.dispatch('clearSession')" class="ml-3">Cerrar session</b-button>
    </div>
  </b-container>
</template>

<script>
export default {
  name: 'Header',
  props: {
    title: String
  }
}
</script>

<style scoped>
.header-gradient { background-image: linear-gradient(red, black);}
</style>