<template>
  <div id="app">
      <Header title="Embajada de la República Bolivariana de Venezuela en Australia"/>
    <b-container v-if="$store.state.session" fluid>
      <b-col>
        <Menu>
        </Menu>
      </b-col>
      <b-col>
        <router-view/>
      </b-col>
  </b-container>
  <b-container v-else fluid>
    <login/>
  </b-container>
  </div>
</template>

<script>
import Header from './components/parts/Header.vue';
import Menu from './components/Menu.vue';
import login from './views/login.vue';

export default {
  name: 'App',
  components: {
    Header,
    Menu,
    login
  }
}
</script>
