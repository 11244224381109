<template>
  <div>
    <b-card border-variant="danger" :header="title" header-border-variant="danger" header-text-variant="white" header-bg-variant="danger" class="mb-3">
        <b-card-text>
          <slot></slot>
        </b-card-text>
      </b-card>
  </div>
</template>

<script>
export default {
  name: 'box',
  props: {
    title: String
  }
}
</script>